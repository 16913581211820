import barba from '@barba/core'
import LazyLoad from 'vanilla-lazyload'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { backendKeyBind, calendar, clickToScrollToBlock, eventsBlockImageHeight, homeBannerSecondaryContent, newsBlockImageHeight, ogContent, pageTitle, slugifyProvider, initSopfeuWidgets } from './functions/functions.js'
import { dynamicPackage } from './functions/dynamic.js'
import { resetDropzone, formsPackage, clearInput } from './functions/form.js'
import { overlayCalendar, overlayDropdown, overlayFastLinks, overlayMobileMenu, overlayPopup, overlaySearch, overlayShare  } from './functions/overlays.js'
import { Accordions } from './classes/Accordions.js'
import Overlay from './vendor/overlay.js'
import { formContact, formExample, formJobs, formNewsletter, formSuccess } from './functions/validation.js'
import { masksPackage } from './functions/masks.js'
import { bannerAlerts } from './functions/banner-alerts.js'
import { homeBannerSwiper, swiperEvents, swiperLogos } from './functions/sliders.js'
import { removeHoverOnMobile, sizeTitle } from './functions/helper.js'
import { backToTopAndLinks } from './functions/back-to-top-and-links.js'

window.formSuccess = formSuccess
window.resetDropzone = resetDropzone
window.slugifyProvider = slugifyProvider
window.barba = barba

export const initBarba = () => {

  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()

    backToTopAndLinks()
    overlayShare()
    overlayPopup()
    overlaySearch()
    overlayDropdown()
    overlayMobileMenu()
    overlayFastLinks()
    bannerAlerts()
    clearInput()
    removeHoverOnMobile()
    backendKeyBind()
    swiperLogos()
    formNewsletter()
    initSopfeuWidgets()
  })

  barba.hooks.enter ((data) => {
    ogContent(data)
  })

  barba.hooks.afterEnter(() => {
    if(document.querySelector('.fileField')) {
      if (typeof $('[data-control=multi-file-uploader]').multiFileUploader == 'function') { //Fix de marde, sinon certaines page crash
        $('[data-control=multi-file-uploader]').multiFileUploader()
      }
    }
  })

  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
    if(window['swiper-logos'] != undefined)
      window['swiper-logos'].destroy()
    if(window['bannerAlertsSwiper'] != undefined)
      window['bannerAlertsSwiper']
  })

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [
      defaultTransition()
    ],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          masksPackage()
          dynamicPackage()
          formExample()
          calendar()
          sizeTitle({ caract01: 22, caract02: 28, caract03: 35 })
          pageTitle()
          clickToScrollToBlock({ selector: '[data-js="scrolldown"]', scrollTo: '[data-js="scrolldown-destination"]' })
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          homeBannerSwiper()
          homeBannerSecondaryContent()
          sizeTitle({ caract01: 30, caract02: 45, caract03: 60 })
          swiperEvents()
          clickToScrollToBlock({ selector: '[data-js="scrolldown"]', scrollTo: '[data-js="scrolldown-destination"]' })
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
          window['homeBannerSwiper'].destroy()
          window['eventsSwiper'].destroy()
        }
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          formsPackage()
          dynamicPackage()
          sizeTitle({ caract01: 22, caract02: 28, caract03: 35 })
          pageTitle()
          newsBlockImageHeight()
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'newsDetailView',
        beforeEnter() {
          dynamicPackage()
          sizeTitle({ caract01: 22, caract02: 28, caract03: 35 })
          pageTitle()
          clickToScrollToBlock({ selector: '[data-js="scrolldown"]', scrollTo: '[data-js="scrolldown-destination"]' })
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'eventsListView',
        beforeEnter() {
          formsPackage()
          calendar()
          overlayCalendar()
          dynamicPackage()
          sizeTitle({ caract01: 22, caract02: 28, caract03: 35 })
          pageTitle()
          eventsBlockImageHeight()
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'eventDetailView',
        beforeEnter() {
          dynamicPackage()
          sizeTitle({ caract01: 22, caract02: 28, caract03: 35 })
          pageTitle()
          clickToScrollToBlock({ selector: '[data-js="scrolldown"]', scrollTo: '[data-js="scrolldown-destination"]' })
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'jobsListView',
        beforeEnter() {
          dynamicPackage()
          sizeTitle({ caract01: 22, caract02: 28, caract03: 35 })
          formsPackage()
          masksPackage()
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
          formJobs()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'jobDetailView',
        beforeEnter() {
          dynamicPackage()
          sizeTitle({ caract01: 22, caract02: 28, caract03: 35 })
          formsPackage()
          masksPackage()
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
          formJobs()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'searchView',
        beforeEnter() {
          formsPackage()
          masksPackage()
          dynamicPackage()
          sizeTitle({ caract01: 22, caract02: 28, caract03: 35 })
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          masksPackage()
          dynamicPackage()
          formContact()
          sizeTitle({ caract01: 22, caract02: 28, caract03: 35 })
          pageTitle()
          clickToScrollToBlock({ selector: '[data-js="scrolldown"]', scrollTo: '[data-js="scrolldown-destination"]' })
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'sectionHomeView',
        beforeEnter() {
          sizeTitle({ caract01: 22, caract02: 28, caract03: 35 })
          pageTitle()
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
    ]
  })
}
