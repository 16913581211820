// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes

import Swiper from 'swiper/bundle'
import { OBSERVER } from './../main.js'

export function homeBannerSwiper() {
  let totalSlidesLength = document.querySelectorAll('.home-banner .swiper-slide').length
  let totalEl = document.querySelector('[data-swiper-total="home-banner"]')
  totalEl.innerHTML = totalSlidesLength < 10 ? `0${totalSlidesLength}` : totalSlidesLength

  window['homeBannerSwiper'] = new Swiper('[data-swiper="home-banner"]', {
    effect: 'fade',
    threshold: 10,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 25,
    pagination: {
      el: '[data-swiper-pagination="home-banner"]',
      clickable: true
    },
    autoplay: {
      delay: 7000,
    },
    on: {
      init: function(sw){
        let current = document.querySelector('[data-swiper-current="home-banner"]')
        current.innerHTML = sw.realIndex + 1 < 10 ? `0${sw.realIndex +  1}` : sw.realIndex +  1
      },
      slideChange: function(sw){
        let current = document.querySelector('[data-swiper-current="home-banner"]')
        current.innerHTML = sw.realIndex + 1 < 10 ? `0${sw.realIndex +  1}` : sw.realIndex +  1
      }
    },
  })
}


export function swiperEvents() {
  const container = document.querySelector('.events-slider')
  
  if (typeof (container) != 'undefined' && container != null) {
    let totalSlides = document.querySelectorAll('.events-slider .swiper-slide').length

    window['eventsSwiper'] = new Swiper('[data-swiper="events"]', {
      speed: 700,
      threshold: 10,
      slidesPerView: 1,
      loop: (totalSlides > 1),
      loopedSlides: 3,
      navigation: {
        prevEl: '[data-swiper-prev="events"]',
        nextEl: '[data-swiper-next="events"]',
      },
      autoplay: {
        delay: 7000,
      },
    })

    const setEventImageDimensionVariable = () => {
      const event = document.querySelector('[data-event-images]')
      if ( typeof event !== 'undefined' && event !== null) {
        document.documentElement.style.setProperty('--event-images-dimension', `${event.offsetHeight}px`)
      }
    }

    setEventImageDimensionVariable()

    OBSERVER.add({
      name: 'setEventImageDimensionVariable',
      event: 'resize',
      function: setEventImageDimensionVariable
    })

    OBSERVER.on('setEventImageDimensionVariable')
  }
}


export function swiperLogos() {
  let isInit = false

  const swiperLogosOnResize = () => {
    let ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    if (ww <= 768 && !isInit) {
      window['swiper-logos'] = new Swiper('[data-swiper="logos"]', {
        speed: 700,
        threshold: 10,
        slidesPerView: 'auto',
        loop: true,
        autoplay: {
          delay: 7000,
        },
      })
      isInit = true
    } else if(ww > 768 && isInit) {
      window['swiper-logos'].destroy()
      isInit = false
    }
  }

  swiperLogosOnResize()

  OBSERVER.add({
    name: 'swiperLogos',
    event: 'resize',
    function: swiperLogosOnResize
  })

  OBSERVER.on('swiperLogos')
}