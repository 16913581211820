import $ from 'jquery'
import Overlay from '../vendor/overlay.js'
import { OBSERVER } from '../main.js'
import { copyTextToClipboard } from './functions.js'
import { Accordions } from '../classes/Accordions.js'

export function overlayDropdown() {
  new Overlay({
    name: 'dropdown',
    click: {
      buttons: {
        trigger: '[data-js="trigger-overlay-dropdown"]',
        close: '[data-js="close-overlay-dropdown"], a[href]',
        switch: '[data-js="toggle-overlay-search"]'
      }
    },
    options: {
      speed: 600,
      goToSelector: 'html, body',
    }
  }).init()
}

export function overlayMobileMenu() {
  new Overlay({
    name: 'mobile-menu',
    events: { close: true },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-mobile-menu"]',
        switch: '[data-js="toggle-overlay-search"]',
        close: '[data-js="close-overlay-mobile-menu"], a[href]'
      }
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
    }
  }).init()

  const level1 = new Accordions({
    containerClass: 'js-accordions-menu',
    scrollToAccordion: false
  })

  const onClose = () => {
    level1.closeAll()
  }

  OBSERVER.add({
    name: 'overlayMobileMenu',
    event: 'onCloseOverlayMobile-menu',
    function: onClose
  })

  OBSERVER.on('overlayMobileMenu')
}

export function overlayFastLinks() {
  new Overlay({
    name: 'fast-links',
    events: { close: true },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-fast-links"]',
        switch: '[data-js="toggle-overlay-search"], [data-js="trigger-overlay-dropdown"], [data-js="toggle-overlay-mobile-menu"]',
        close: '[data-js="close-overlay-fast-links"], a[href]'
      }
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
    }
  }).init()
}

export function overlaySearch() {
  new Overlay({
    name: 'search',
    events: {
      openComplete: true
    },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-search"]',
        close: '[data-js="close-overlay-search"], a[href]',
        switch: '[data-js="trigger-overlay-dropdown"], [data-js="toggle-overlay-mobile-menu"]'
      }
    },
    animations: {
      addTransition: true,
      selector: '.overlay-search__container',
      styles: [{
        property: 'height',
        value: '100%',
        easing: 'easeInOutQuart'
      }]
    },
    options: {
      speed: 600,
      goToSelector: 'html, body',
      closeOnResize: false,
    }
  }).init()

  const onFocus = () => {
    document.getElementById('form-search-input').focus()
  }

  OBSERVER.add({
    name: 'overlaySearch',
    event: 'onOpenCompleteOverlaySearch',
    function: onFocus
  })

  OBSERVER.on('overlaySearch')
}

export function overlayPopup() {
  new Overlay({
    name: 'popup',
    click: {
      buttons: {
        close: '[data-js="close-overlay-popup"]',
      }
    },
    timeout: {
      delay: 2000
    },
    options: {
      speed: 600,
    }
  }).init()
}

export function overlayShare() {
  let siteName = window.config.site_name

  new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '[data-js="open-overlay-share"]',
        close: '[data-js="close-overlay-share"]',
      }
    },
    options: {
      speed: 600,
    }
  }).init()

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  $('.js-copy-share').on('click', function() {
    let link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('L’adresse URL a bien été copiée sur votre presse-papiers.')
    return false
  })

  $('.js-share').on('click', function(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    }
    $('#emailShareBtn').attr('href', 'mailto:?Subject=' + siteName + '&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  $('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    $('#emailShareBtn').attr('href', 'mailto:?Subject=' + siteName + '&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })
}

export function overlayCalendar() {
  let calendar = new Overlay({
    name: 'calendar',
    click: {
      buttons: {
        open: '[data-js="open-overlay-calendar"]',
        close: '[data-js="close-overlay-calendar"], a[href]',
      }
    },
    options: {
      speed: 600,
      goToSelector: 'html, body',
    }
  })

  calendar.init()
}
